.SearchBar {
  font-size: 16px;
  position: relative;
  padding: 3px 0;
  width: 250px;
  float: right;
  margin-right: 40px;
  margin-left: 30px;
}

.SearchBar-input {
  border: none;
  border-bottom: 1px solid #8cc63f;
  display: block;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  padding: 0.5rem 0;
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: black;
  outline: none;
}

.SearchBar-icon {
  position: absolute;
  color: #8cc63f;
  left: 102%;
  top: 16px;
  transition: 0.2s ease all;
  width: 18px;
}

.SearchBar-bar {
  display: block;
  width: 0;
  margin-left: 50%;
  height: 1px;
  background: #8cc63f;
  opacity: 0;
  transition: 0.2s ease all;
}

.SearchBar-input:focus ~ .SearchBar-bar {
  opacity: 1;
  width: 100%;
  margin-left: 0;
}
