html,
body {
  overflow-y: visible;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
  font-size: 16px !important;
}
* {
  --primary-color: #8cc63f;
  --primary-color-50: #8bc63f80;
  --secondary-color: #f89f41;
  --error-color: #f0513c;
  --warning-color: #ef5938;
  --info-color: #17ab7d;
  --success-color: #18ab50;
}

h1.title-seccion {
  margin-top: 0;
  padding: 20px;
  text-align: center;
  color: #98b81a;
  text-transform: uppercase;
  background: white;
}

h2.subtitulo-seccion {
  color: white;
  text-align: center;
}

.title-seccion {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 20px;
}

a {
  text-decoration: none;
  color: black;
}
/* COMMON FOR APP */
/* Respect "_" to name in order to avoid conflicts*/
/* Cards */
.bordered_shadow {
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  border-radius: 2px;
  z-index: 1;
}

.card_wrapper {
  width: 80%;
  max-width: 800px;
  min-width: 500px;
  margin: 50px auto;
  min-height: 355px;
}

div.card_wrapper_action {
  justify-content: flex-end;
}

/* Lists */
.common_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

@media print {
  body {
    display: none;
  }
}
