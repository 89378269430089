.Videos-toolbar {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.videos {
  background: url('../../assets/madera.jpg') center;
  width: 100%;
  height: 100%;
  background-size: cover;
  min-height: calc(100vh - 50px);
  padding: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(205px, 205px));
  gap: 5px;
  justify-items: center;
}

.video {
  position: relative;
  width: 230px;
  height: 200px;
  color: white;
  border: 2px solid #8cc63f;
}

.video .title {
  display: block;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 3%;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.8s ease;
}

.video .title {
  background: rgba(0, 0, 0, 0.8);
}

.video .title .text {
  white-space: initial;
}

.video .duration {
  position: absolute;
  left: -15%;
  bottom: 12%;
  opacity: 0;
  transition: opacity 0.8s ease, left 0.5s ease;
  background: #8cc63f;
  padding: 5px;
}

.video .play {
  position: absolute;
  font-size: 200%;
  opacity: 0;
  bottom: 12%;
  right: 5%;
  color: #8cc63f;
  text-shadow: -1px -1px 0 #8cc63f, -1px 1px 0 #8cc63f, 1px -1px 0 #8cc63f,
    1px 1px 0 #8cc63f, -1px 0 0 #8cc63f, 0 -1px 0 #8cc63f, 1px 0 0 #8cc63f,
    0 1px 0 #8cc63f, 2px 0 0 #8cc63f;
  transition: opacity 0.8s ease, right 0.5s ease;
}

.video .duration {
  opacity: 1;
  left: 5%;
}

.video .play {
  opacity: 1;
  right: 5%;
}

.video .title:after {
  content: '';
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.video .title .text {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: break-spaces;
  overflow: hidden;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
