.search {
  padding: 10px;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.filters {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
