.PortadaCuadernillo-element {
  width: 156px;
  height: 230px;
  float: left;
  margin: 0 20px 0 0;
  padding: 4px 0 66px;
  position: relative;
  list-style: none;
  box-sizing: content-box;
}

.PortadaCuadernillo-link {
  text-decoration: none;
}

.PortadaCuadernillo-cover {
  width: 156px;
  height: 200px;
}

.PortadaCuadernillo-asignatura {
  text-decoration: none;
  color: white;
  box-shadow: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding-top: 45px;
  margin-top: 0;
}

.PortadaCuadernillo-title {
  text-decoration: none;
  color: white;
  font-weight: bold;
  box-shadow: none;
  display: block;
  text-align: center;
  padding: 25px 10px 0;
  margin-top: 0;
  font-size: 0.8rem;
}

.PortadaCuadernillo-footer {
  background: white;
  height: 40px;
}

.PortadaCuadernillo-footer-logo {
  height: 20px;
  line-height: 40px;
  vertical-align: middle;
  padding-left: 3px;
}

.PortadaCuadernillo-footer-detail {
  text-transform: uppercase;
  color: black;
  line-height: 40px;
  vertical-align: middle;
  font-size: 9px;
  padding-left: 10px;
}
