.Asignaturas-toolbar {
  display: flex;
  justify-content: space-around;
}
.Cuadernillos {
  background: url('../../assets/madera.jpg') center;
  float: left;
  width: 100%;
  height: 100%;
  background-size: cover;
  min-height: calc(100vh - 50px);
  padding: 20px;
  box-sizing: border-box;
}

.Cuadernillos ul {
  float: left;
  padding: 0 80px;
  background: url('../../assets/estanteria.png') 0 -30px;
  background-repeat: repeat;
  width: 100%;
  box-sizing: border-box;
}

.appbar {
  color: #8cc63f !important;
  background: white !important;
}
