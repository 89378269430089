.viewer {
  height: calc(100vh - 50px);
  overflow: scroll;
  isolation: isolate;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 5px;
  background: white;
}
.canvas {
  width: 100%;
  margin-bottom: 60px;
}
.actions {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: white;
  border-top: 1px solid black;
}
.input {
  width: 30px;
  text-align: center;
}
.error {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}
