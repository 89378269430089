.Video {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.Video-element {
  width: 90%;
  height: 80vh;
}
