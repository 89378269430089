.adminColor {
  color: var(--success-color);
}
.accountColor {
  color: var(--secondary-color);
}
.globalSearch {
  display: flex;
  gap: 8px;
  color: #2b6fb8;
}
.logout {
  color: var(--error-color);
}
