.Card {
  height: 220px;
  background-color: white;
  border-radius: 10px;
  text-decoration: none;
  overflow: hidden;
  box-shadow: 0 0px 0px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.4s ease, top 0.4s ease, width 0.4s ease,
    height 0.4s ease;
}
.Card a {
  text-decoration: none;
}
.Card:hover {
  box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.5);
  top: -5px;
}

.Card-top {
  width: 100%;
  height: 150px;
  background-color: #98b81a;
  background-image: url('../../assets/mundoestudiante-logo-texto.png');
  background-position: center center;
  background-repeat: no-repeat;
}
.Card-bottom {
  margin-left: 5%;
  margin-top: 12px;
  width: 90%;
  text-align: center;
}
.Card-title {
  font-size: 1.1rem;
  color: #555555;
  margin-bottom: 2px;
  text-transform: capitalize;
}
