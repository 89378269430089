.Header {
  text-align: center;
  line-height: 50px;
  width: 100%;
  background: black;
  height: 50px;
}

.Header-logo {
  float: left;
  margin-left: 50px;
  margin-top: 5px;
  height: 40px;
}

.Header-info {
  overflow: hidden;
  white-space: nowrap;
  width: 35vw;
  text-overflow: ellipsis;
  display: inline-block;
  color: white;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.Header-navigation {
  float: right;
  margin-right: 50px;
}

.Header-navigation a {
  text-decoration: none;
  color: #f4f4f4;
  margin-left: 30px;
  text-transform: capitalize;
  opacity: 0.7;
}

.Header-navigation a:hover {
  color: white;
  opacity: 0.9;
  border-bottom: 2px solid white;
  padding-bottom: 10px;
}

.Header-navigation a.active--link {
  color: white;
  opacity: 0.8;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}

.Header-navigation a.active--link:hover {
  color: white;
  opacity: 0.9;
  border-bottom: 2px solid white;
  padding-bottom: 10px;
}

.Header-menu {
  float: right;
  margin-right: 15px;
}

@media (max-width: 1000px) {
  .Header-info {
    width: 50vw;
  }

  .Header-navigation {
    margin-right: 25px;
  }
  .Header-navigation a {
    text-decoration: none;
    color: lightcoral;
  }
  .Header-navigation a:hover {
    color: black;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
  }
  .Header-menu {
    margin-right: 0;
  }
}
